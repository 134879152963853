export async function waitForField<T>(params: IParams): Promise<T> {
  const { field, target = window, errorTimeout = 5000, pollInterval = 100 } = params

  return new Promise<T>((resolve, reject) => {
    if (field in target) {
      resolve(target[field] as T)
      return
    }

    const intervalId = setInterval(() => {
      if (field in target) {
        clearInterval(intervalId)
        resolve(target[field] as T)
      }
    }, pollInterval)

    const timeout = setTimeout(() => {
      clearInterval(intervalId)
      clearTimeout(timeout)
      reject(new Error(`Field "${field}" not found after waiting for a certain time`))
    }, errorTimeout)
  })
}

interface IParams {
  field: string
  target?: Record<string, unknown>
  errorTimeout?: number
  pollInterval?: number
}
