import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { ILastMonthAds } from '@obeta/models/lib/models/LastMonthAds/LastMonthAds'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ShopDomains } from '@obeta/utils/lib/domains'
import { getDateNMonthsAgo } from '@obeta/utils/lib/date-helpers/getDateNMonthsAgo'

const lastMonthAdsQuery = gql`
  query lastMonthAdverts(
    $filters: AdvertisementOfTheMonthFiltersInput
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    advertisementsOfTheMonths(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          text
          title
          oxomiCatalogId
          activeMonth
        }
      }
    }
  }
`

interface IAllFilters {
  showInApp?: { eq: boolean }
  showForObeta?: { eq: boolean }
  showForEldis?: { eq: boolean }
  showForHaeusler?: { eq: boolean }
  activeMonth: { gte: string }
}

const transformTeasersResponse = (data) => {
  return data.map((data) => {
    return {
      id: data.id,
      ...data.attributes,
      oxomiCatalogId: data.attributes.oxomiCatalogId,
    }
  })
}

type MonthAdvertsProps = {
  activeMonth?: {
    gte: string
  }
}

export const useMonthAdverts = ({ activeMonth }: MonthAdvertsProps = {}) => {
  const [monthAdverts, setMonthAdverts] = useState<ILastMonthAds[]>([])
  const [isLoading, setLoading] = useState(false)
  const apolloClient = useStrapiGql()
  const client = useApolloClient(apolloClient)
  const isMounted = useRef(true)
  const domain = useAppDomain()
  const isWeb = isPlatform('web')

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getMonthAdverts = async () => {
      if (!isMounted.current) return
      setLoading(true)

      const getFilters = () => {
        const variables: IAllFilters = {
          activeMonth: activeMonth ?? getDateNMonthsAgo(2),
        }

        if (isWeb) {
          if (domain === ShopDomains.Obeta) {
            variables.showForObeta = { eq: true }
          } else if (domain === ShopDomains.Eldis) {
            variables.showForEldis = { eq: true }
          } else {
            variables.showForHaeusler = { eq: true }
          }
        } else {
          variables.showInApp = { eq: true }
        }

        return variables
      }

      try {
        const response = await client.query({
          query: lastMonthAdsQuery,
          variables: {
            filters: getFilters(),
            sort: ['activeMonth:asc'],
          },
        })

        const monthAdvertsList = transformTeasersResponse(
          response.data.advertisementsOfTheMonths.data
        )

        setMonthAdverts(monthAdvertsList)

        setLoading(false)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }

        setLoading(false)
      }
    }

    getMonthAdverts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, isWeb])

  return { monthAdverts, isLoading }
}
