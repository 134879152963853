import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Typography, Link } from '@mui/material'
import { GuardedImageTile } from '../tiles/GuardedImageTile'
import styles from './TeaserCard.module.scss'
import { ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Browser } from '@capacitor/browser'
import { trackClick } from '@obeta/utils/lib/tracking'
import { isInternalLink } from '@obeta/utils/lib/isInternalLink'

interface ITeaserCardProps extends ITeaser {
  isSmall?: boolean
}

const prepareLink = (url: string) => {
  let link
  if (url && (url.startsWith('/') || !isInternalLink(url))) {
    link = url
  } else {
    link = url ? `/${url}` : null
  }
  return link
}

export const TeaserCard: React.FC<ITeaserCardProps> = (props) => {
  const { id, teaserText, imageUrl, imageAlt, newsType, isSmall, internalName } = props
  const changeSearchParams = useChangeSearchParams()
  const { mobile } = useBreakpoints()
  const history = useHistory()

  const { url, actionContext } = useMemo(() => {
    if (newsType) {
      if ('sapId' in newsType) {
        const articleId = newsType.sapId
        return {
          url: `${ShopRoutes.ArticleDetailPage}/${articleId}`,
          actionContext: {
            context: {
              articleId: articleId,
              type: 'article-details',
            },
          },
        }
      }

      if ('content' in newsType) {
        return {
          url: `${ShopRoutes.TeaserDetailPage}/${id}`,
          actionContext: {
            context: {
              newsId: id,
              type: 'teaser-details',
            },
          },
        }
      }

      if ('url' in newsType) {
        const link = prepareLink(newsType.url)

        return {
          url: isInternalLink(newsType.url) ? link : null,
          actionContext: {
            context: {
              link,
            },
          },
        }
      }
    }
    return {}
  }, [id, newsType])

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // track both: one action with a concrete name containing an id and one generic action containing specific infos in context
    trackClick(`teaser-card-${id}`, { internalName })
    if (actionContext) trackClick('teaser-id-GENERIC', actionContext)

    if (!(e.getModifierState('Control') || e.getModifierState('Meta')) && url) {
      e.preventDefault()
      e.stopPropagation()
      history.push(url)
      return
    }

    if ('openInNewWindow' in newsType && !url) {
      const target = newsType.openInNewWindow ? '_blank' : '_self'
      if (isPlatform('web')) {
        window.open(newsType.url, target)
      } else {
        Browser.open({ url: newsType.url })
      }
      return
    }

    //todo, not deplinkable?
    if ('algoliaQuery' in newsType) {
      e.preventDefault()
      e.stopPropagation()
      const searchParams: ArticleSearchParams = {
        searchString: newsType.algoliaQuery,
        suppliers: {},
      }
      if ('supplierName' in newsType && newsType.supplierName) {
        newsType.supplierName
          .split(',')
          .filter(Boolean)
          .forEach((name) => {
            if (searchParams.suppliers) {
              const supName = name.trim()
              searchParams.suppliers[supName] = {
                id: supName,
                name: supName,
              }
            }
          })
      }
      changeSearchParams({
        searchParams,
        route: 'push',
      })
    }
    e.stopPropagation()
  }

  const teaserTextVariant = mobile ? 'bodyBold' : 'headline4Bold'

  return (
    <Link
      href={url || '#'}
      className={clsx(styles['teaser-wrapper'])}
      onClick={handleClick}
      underline="none"
    >
      <div className={clsx(styles['teaser-img-wrapper'])}>
        <GuardedImageTile
          className={styles.imageObject}
          imageUrl={imageUrl}
          alt={imageAlt}
          imageFit="cover"
          backgroundColor="transparent"
        />
      </div>
      <div
        className={clsx(styles['teaser-content'], { [styles['teaser-content__small']]: isSmall })}
      >
        <Typography variant={teaserTextVariant} className={styles['teaser-title']}>
          {teaserText}
        </Typography>
      </div>
    </Link>
  )
}
