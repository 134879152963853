import { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { TeaserCard } from '@obeta/components/lib/teaser-card/TeaserCard'
import { EPlacement, ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useTeasers } from './hooks/useTeasers'
import { ITeaserSectionProps } from './types'
import styles from './TeasersSection.module.scss'
import { useStartPageData } from '@obeta/data/lib/hooks/useStartPageDataProvider'
import dayjs from 'dayjs'

const getCardSize = (teasersLength: number, teaserIndex: number, mobile: boolean): boolean => {
  if (teasersLength <= 2) {
    return false
  }

  /**
   * This two arrays depend on the grid layout, which consists of 5 teaser cards
   * 1 (or true) means that a card is rendered in a small size
   * 0 (or false) means that a card is rendered in a normal size
   * */
  const desktopCardSizes = [1, 1, 1, 0, 0]
  const mobileCardSizes = [1, 1, 0, 1, 1]
  const cardSizes = mobile ? mobileCardSizes : desktopCardSizes

  return !!cardSizes[teaserIndex]
}

const chunk = <T,>(arr: T[], size: number): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}

export const TeasersSection: React.FC<ITeaserSectionProps> = (props) => {
  const filters = useMemo(() => {
    return { placement: { eq: props.placement } }
  }, [props.placement])

  const {
    teasersBlock1,
    teasersBlock2,
    teasersBlock3,
    teasersAll,
    setTeasersBlock1,
    setTeasersBlock2,
    setTeasersBlock3,
    setTeasersAll,
    timeLimit,
    setTimeLimit,
  } = useStartPageData()

  let cachedTeasers: ITeaser[] = []
  if (props.placement === EPlacement.Block1) {
    cachedTeasers = teasersBlock1
  }
  if (props.placement === EPlacement.Block2) {
    cachedTeasers = teasersBlock2
  }
  if (props.placement === EPlacement.Block3) {
    cachedTeasers = teasersBlock3
  }
  if (props.placement === EPlacement.All) {
    cachedTeasers = teasersAll
  }
  const timeLimitExpired = timeLimit && timeLimit < dayjs(new Date()).unix()

  useEffect(() => {
    if (timeLimit && !timeLimitExpired) return
    setTimeLimit(dayjs(new Date()).add(1, 'hours').unix())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { teasers } = useTeasers({
    filters,
    hasCachedTeasersAndIsNotExpired: cachedTeasers.length > 0 && !timeLimitExpired,
  })

  useEffect(() => {
    if (props.placement === EPlacement.Block1 && teasers.length > 0) {
      setTeasersBlock1(teasers)
    }
    if (props.placement === EPlacement.Block2 && teasers.length > 0) {
      setTeasersBlock2(teasers)
    }
    if (props.placement === EPlacement.Block3 && teasers.length > 0) {
      setTeasersBlock3(teasers)
    }
    if (props.placement === EPlacement.All && teasers.length > 0) {
      setTeasersAll(teasers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teasers, props.placement])

  return <TeasersSectionView {...props} teasers={cachedTeasers} />
}

type TeaserSectionViewProps = ITeaserSectionProps & {
  teasers: ITeaser[]
}

export const TeasersSectionView: React.FC<TeaserSectionViewProps> = (props) => {
  const { teasers, placement, maxEls } = props

  const { mobile } = useBreakpoints()

  const teasersGroups = useMemo(() => {
    //block1 -> two elements side by side
    //block2,3 -> all teasers from the API
    if (placement === EPlacement.Block1) {
      return [teasers.slice(0, maxEls)]
    }

    /** Group teasers in array in order to repeat the css grid pattern */
    return chunk(teasers, maxEls || teasers.length)
  }, [placement, maxEls, teasers])

  return (
    <div className={styles['teasers-section-wrapper']}>
      {teasersGroups.map((teaserGroup, i) => {
        return (
          <div
            key={i}
            className={clsx(styles['teasers-section'], {
              [styles.extended]: placement !== EPlacement.Block1,
            })}
          >
            {teaserGroup.map((teaser, i, arr) => {
              return (
                <div className={styles.aspect} key={teaser.id}>
                  <TeaserCard {...teaser} isSmall={getCardSize(arr.length, i, mobile)} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
