export async function waitForResolve(params: IParams): Promise<void> {
  const { callback, errorTimeout = 5000, pollInterval = 100 } = params

  return new Promise<void>((resolve, reject) => {
    if (callback()) {
      resolve()
      return
    }

    const intervalId = setInterval(() => {
      if (callback()) {
        clearInterval(intervalId)
        resolve()
      }
    }, pollInterval)

    const timeout = setTimeout(() => {
      clearInterval(intervalId)
      clearTimeout(timeout)
      reject(new Error('Callback did not return true after waiting for a certain time'))
    }, errorTimeout)
  })
}

interface IParams {
  callback: () => boolean
  errorTimeout?: number
  pollInterval?: number
}
