import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { EPlacement, ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import {
  ARTICLE_TYPE_FIELDS,
  ENHANCED_TYPE_FIELDS,
  LINK_TYPE_FIELDS,
  SEARCH_TYPE_FIELDS,
} from '@obeta/data/lib/queries/teasersFragments'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { ShopDomains } from '@obeta/utils/lib/domains'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { getShuffledTeasersList } from './teasersShuffle'

const teasersQuery = gql`
  ${ARTICLE_TYPE_FIELDS}
  ${ENHANCED_TYPE_FIELDS}
  ${SEARCH_TYPE_FIELDS}
  ${LINK_TYPE_FIELDS}
  query obetaTeasers(
    $filters: ObetaTeaserFiltersInput
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    obetaTeasers(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          actionWeek
          redArticle
          weekendDeal
          megaDeal
          brand
          teaserText
          placement
          showInApp
          showForObeta
          showForEldis
          showForHaeusler
          internalName
          newsType {
            ...Article
            ...Enhanced
            ...Search
            ...Link
          }
          startDate
          endDate
          teaser {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

export interface IAllFilters {
  placement?: { eq: EPlacement }
  showInApp?: { eq: boolean }
  showForObeta?: { eq: boolean }
  showForEldis?: { eq: boolean }
  showForHaeusler?: { eq: boolean }
  endDate?: { gte: Date }
  startDate?: { lte: Date }
  megaDeal?: { eq: boolean }
  weekendDeal?: { eq: boolean }
  redArticle?: { eq: boolean }
  actionWeek?: { eq: boolean }
  teaserText?: { eq: string }
  or?: {
    brand: {
      contains: string
    }
  }[]
}

export interface ITeasersProps {
  filters: IAllFilters
  pagination?: {
    limit: number
  }
  hasCachedTeasersAndIsNotExpired?: boolean
}

const transformTeasersResponse = (data) => {
  return data.map((data) => {
    const teaser = {
      id: data.id,
      ...data.attributes,
      imageUrl: data.attributes.teaser.data?.attributes.url,
      imageAlt: data.attributes.teaser.data?.attributes.alternativeText,
      newsType: data.attributes.newsType[0],
    }
    delete teaser.__typename
    delete teaser.teaser

    return teaser
  })
}

export const useTeasers = ({
  filters,
  pagination,
  hasCachedTeasersAndIsNotExpired,
}: ITeasersProps) => {
  const [teasers, setTeasers] = useState<ITeaser[]>([])
  const [isLoading, setLoading] = useState(false)
  const currentDate = new Date()
  const apolloClient = useStrapiGql()

  const client = useApolloClient(apolloClient)
  const isMounted = useRef(true)
  const domain = useAppDomain()
  const isWeb = isPlatform('web')

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getTeasers = async () => {
      if (hasCachedTeasersAndIsNotExpired) return
      if (!isMounted.current) return

      setLoading(true)

      const getFilters = () => {
        const variables: IAllFilters = {
          ...filters,
          endDate: { gte: currentDate },
          startDate: { lte: currentDate },
        }

        if (isWeb) {
          if (domain === ShopDomains.Obeta) {
            variables.showForObeta = { eq: true }
          } else if (domain === ShopDomains.Eldis) {
            variables.showForEldis = { eq: true }
          } else {
            variables.showForHaeusler = { eq: true }
          }
        } else {
          variables.showInApp = { eq: true }
        }

        return variables
      }

      try {
        const response = await client.query({
          query: teasersQuery,
          variables: {
            pagination: pagination ?? {},
            filters: getFilters(),
          },
        })

        const teasersList = transformTeasersResponse(response.data.obetaTeasers.data)
        if (
          filters.placement?.eq &&
          [EPlacement.Block2, EPlacement.Block3].includes(filters.placement.eq)
        ) {
          const shuffledTeasersList = getShuffledTeasersList(teasersList, filters.placement.eq)
          setTeasers(shuffledTeasersList)
        } else {
          setTeasers(teasersList)
        }

        setLoading(false)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }

        setLoading(false)
      }
    }

    getTeasers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, domain, isWeb, pagination])

  return { teasers, isLoading }
}
