import { EPlacement, ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { localStorageManager } from '@obeta/utils/lib/localStorage'

const teasersShufflingLSManager = localStorageManager<ITeasersShufflingLS>('shuffledTeasers')

/** Fisher-Yates shuffling algorithm */
function fyShuffle<T>(arr: T[]): T[] {
  let i = arr.length
  while (--i > 0) {
    const randIndex = Math.floor(Math.random() * (i + 1))
    ;[arr[randIndex], arr[i]] = [arr[i], arr[randIndex]]
  }
  return arr
}

/** Sorting example:
 * [{id: 11}, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }] as ITeaser[],
 * [1, 3, 5, 7, 4, 6, 2]
 * result: [{"id":11},{"id":1},{"id":3},{"id":5},{"id":7},{"id":4},{"id":6},{"id":2}]
 * */
function sortTeasers(teasers: ITeaser[], teasersIds: number[]): ITeaser[] {
  return teasers.sort((a, b) => {
    const aIndex = teasersIds.indexOf(a.id)
    const bIndex = teasersIds.indexOf(b.id)

    // Both elements are in teasersIds
    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex
    }

    // Only a is in teasersIds
    if (aIndex > -1) {
      return 1
    }

    // Only b is in teasersIds
    if (bIndex > -1) {
      return -1
    }

    // Neither element is in teasersIds
    return 0
  })
}

export function getShuffledTeasersList(teasers: ITeaser[], placement: EPlacement): ITeaser[] {
  const now = new Date()
  const nowTs = now.getTime()
  const shuffleTimeToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    12,
    30
  ).getTime()

  const storedTeasers = teasersShufflingLSManager.get()
  const storedTeasersByPlacement = storedTeasers ? storedTeasers[placement] : null

  const shouldShuffleBecauseTime =
    nowTs > shuffleTimeToday && (storedTeasersByPlacement?.shuffleTimestamp || 0) < shuffleTimeToday

  if (storedTeasersByPlacement && !shouldShuffleBecauseTime) {
    return sortTeasers(teasers, storedTeasersByPlacement.teaserIds)
  } else {
    const teasersIds = teasers.map((teaser) => teaser.id)
    const shuffledTeaserIds = fyShuffle(teasersIds)

    teasersShufflingLSManager.set({
      ...storedTeasers,
      [placement]: {
        shuffleTimestamp: nowTs,
        teaserIds: shuffledTeaserIds,
      },
    })

    return sortTeasers(teasers, shuffledTeaserIds)
  }
}

type ITeasersShufflingLS = Partial<
  Record<
    EPlacement,
    {
      shuffleTimestamp: number
      teaserIds: ITeaser['id'][]
    }
  >
>
