import { gql } from '@apollo/client'

export const ARTICLE_TYPE_FIELDS = gql`
  fragment Article on ComponentNewsTypesArticle {
    id
    sapId
  }
`

export const ENHANCED_TYPE_FIELDS = gql`
  fragment Enhanced on ComponentNewsTypesEnhanced {
    id
    content
  }
`

export const SEARCH_TYPE_FIELDS = gql`
  fragment Search on ComponentNewsTypesSearch {
    id
    algoliaQuery
    supplierName
  }
`

export const LINK_TYPE_FIELDS = gql`
  fragment Link on ComponentNewsTypesLink {
    id
    url
    openInNewWindow
  }
`
