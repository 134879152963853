export interface ITeaser {
  id: number
  imageAlt: string
  imageUrl: string
  teaserText: string
  placement: EPlacement
  newsType: TNews
  actionWeek: boolean
  redArticle: boolean
  weekendDeal: boolean
  megaDeal: boolean
  brand: Record<string, string>[]
  internalName: string
}

export enum EPlacement {
  Block1 = 'block1',
  Block2 = 'block2',
  Block3 = 'block3',
  All = 'All',
}

type TNews = IArticle | IEnhanced | ISearch | ILink
interface IArticle {
  id: string
  sapId: string
}

interface IEnhanced {
  id: string
  content: string
}

interface ISearch {
  id: string
  algoliaQuery: string
  supplierName: string
}

interface ILink {
  id: string
  url: string
  openInNewWindow: boolean
}
